import React from "react";
import { Modal } from "antd";

export default function CommunityModal({ visible, hideComm }) {
  return (
    <Modal
      title="Community v Private Treatment"
      visible={visible}
      closable
      width={768}
      onCancel={hideComm}
      wrapClassName="covid-modal"
      footer={null}
      zIndex={2000}
    >
      <div className="section-heading">
        <h2 className="section-subheader">
        If you want to get acupuncture, but are not sure whether to get a private or community acupuncture treatment, here are some things to consider:
        </h2>
        <h3 className="section-header">Community Acupuncture</h3>
        <p className="section-description">
        Community treatments are provided either in a zero gravity recliner in the living room of the wellness studio or on a treatment table in a small room adjacent to the living room. You may hear the voices of people socializing and sipping tea, consulting with their practitioner or scheduling appointments.  If you like the idea of relaxing with others, this is for you. Since acupuncture affects our qi, or energy, in addition to the physical body, many believe that the energy cultivated in group settings can have a healing effect on its own.
        </p>
        <p className="section-description">
        Because the space is shared, your acupuncturist may only use points on areas of the body easily accessible in public. This is usually sufficient, but can be an issue with certain presentations and with clients who prefer to avoid minimal public exposure.
        </p>
        <p className="section-description">
        Community acupuncture is set up to provide needle-based treatments that are both simple and elegant.
        </p>
        <p className="section-description"><b>Add-Ons</b> are available in the community room. So, you can schedule an Herbal Consultation or Cupping/Gua Sha/Body Work session in addition to your acupuncture treatment. Please contact us if you’d like help doing that. </p>
        <h3 className="section-header">Private or Semi-private Acupuncture</h3>
        <p className="section-description">
        This is done in a separate windowed room, so your acupuncturist can access a wider range of points.This is great for people who want an added layer of privacy or social distancing.
        </p>
        <p className="section-description"><b>Add-Ons</b> are available in the community or single rooms. You can schedule an Herbal Consultation or Cupping/Gua Sha/Body Work session in addition to your acupuncture treatment. Please contact us if you’d like help doing that.</p>
      </div>
    </Modal>
  );
}
