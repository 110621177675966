import React from "react";

export default function Details() {
  return (
    <div className="details">
      <h2 className="detail-heading">Billing</h2>
      <p className="detail-subheading">
        At your first visit to the clinic, we store your credit or debit card
        information securely.
      </p>
      <h2 className="detail-heading">Payment</h2>
      <p className="detail-subheading">
      Please be sure to pay your invoice before your appointment. While paying your invoice, please click the box titled, “Save Card on File.” This will allow you to book future appointments. Moving forward, we will take your payment for you no more than 48 hours before each appointment. 
      </p>
      <h2 className="detail-heading">Scheduling</h2>
      <p className="detail-subheading">
        In order to reduce administrative costs and to make it easier for you to
        achieve your health goals, we like to schedule your treatments for the
        calendar month. That way, we can spend less time scheduling. Should you
        decide to cancel or reschedule your appointment, just contact us or make
        the changes online.
      </p>
      <h2 className="detail-heading">Cancellation and Lateness Policy</h2>
      <p className="detail-subheading">
      If you will not be attending your appointment, please cancel more than 24 hours in advance. Otherwise, you will be charged the price of your appointment. For treatments covered by insurance, the late cancellation/no show fee is $50. We cannot make exceptions, even if your are sick. If you arrive more than 10 minutes late for your appointment, we may not be able to treat you, and your payment will not be refunded.  Your payment allows us to continue providing affordable rates. Thank you for understanding.
      </p>
      <h2 className="detail-heading">Sliding Scale</h2>
      <p className="detail-subheading">
        The <b>Basic Rate</b> sustains our practice at this time. If you are
        able, please consider the <b>Supporter rate</b>, which allows us to see
        patients at the <b>Low Income Rate</b>.
        <br />
        If the Supporter and Basic Rates are not within your budget,{" "}
        <a
          href="https://bodypolitic.janeapp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          please contact us to schedule at the Low Income Rate
        </a>
        .
      </p>
    </div>
  );
}
