import React, {useState, useEffect} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Form, Input, Button, message } from "antd";

const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
        });
    }


    const [form] = Form.useForm()

    useEffect(() => {
        if(status === "success") clearFields();
    }, [status])

    const clearFields = () => {
        setEmail('');
    }


    return (
        <Form
            className=""
            form={form}
            onSubmit={(e) => handleSubmit(e)}
        >
            <h3 className="">
                {status === "success" ? "Thank you for subscribing!" :
                    "Join our email list for future updates"}
            </h3>

            {status === "sending" && (
                <div
                    className=""
                >sending...</div>
            )}
            {status === "error" && (
                <div
                    className=""
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {/* {status === "success" && (
                <div
                    className=""
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )} */}

            {status !== "success" ? (
                <div className="">
                    <Form.Item name="email" value={email} onChange={(e) => setEmail(e.target.value)}  rules={[{ type: "email", required: true }]}>
                        <Input />
                    </Form.Item>

                </div>
            ) : null}

            <Form.Item className="button-container">
                <Button type="primary" className="ant-btn" htmlType="submit" onClick={handleSubmit}>
                    Subscribe
                </Button>
            </Form.Item>

        </Form>
    );
};


const MailchimpForm = props => {
    const url = `https://bodypolitic.us4.list-manage.com/subscribe/post?u=fec932e35fea3ce6521973380&amp;id=1dec9828a7&amp;f_id=0085d4e8f0`;

    return (

        <div className="newsletter-form">
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>

    )
}

export default MailchimpForm;