import React from 'react';
import Fade from "react-reveal/Fade";
import { Row, Col, Card, Button, Modal } from "antd";

const Update = () => {
  return (
    <div className='update'>
      <div className="section-heading update-heading">
        <h2 className="section-header">Important Update</h2>
        <p
          className="section-description service-description"
          style={{ textAlign: "left", maxWidth: "900", marginTop: '25px' }}
        >
            Dear friends,<br/><br/> 
            We’re happy to announce that we are now partnered with Urban Asanas, a health and wellness studio committed to building community with individuals from all socio-economic backgrounds. In addition to our offerings, you can now enjoy yoga classes, a sauna suite and an array of other wellness offerings at the studio. <br/><br/>
            Please help us celebrate this development by scheduling an appointment or, if you’d like to talk with a practitioner, schedule a free consultation.
        </p>
        <a
            href="https://bodypolitic.janeapp.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{marginTop:'24px', display: 'block'}}
          >
            <Button className="full-list">Schedule appointment</Button>
          </a>
      </div>
    </div>
  )
}

export default Update