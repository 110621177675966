import React from "react";
import logo from "../../static/images/logo.png";
import { Row, Col, Table, Button } from "antd";

const communityColumn = [
  {
    title: "",
    width: 100,
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Supporter Rate",
    width: 160,
    dataIndex: "supporter",
    key: "supporter",
  },
  {
    title: "Basic Rate",
    width: 160,
    dataIndex: "basic_rate",
    key: "basic_rate",
  },
  {
    title: "Low Income Rate",
    width: 160,
    dataIndex: "low_income",
    key: "low_income"
  },
];

const communityData = [
  {
    key: 1,
    title: "Follow up",
    supporter: "$65",
    basic_rate: "$55",
    low_income: "$40",
  },
  {
    key: 2,
    title: "Initial",
    supporter: "$75",
    basic_rate: "$65",
    low_income: "$45",
  },
  {
    key: 3,
    title: "",
    supporter: "At this rate you are a supporter of affordable holistic healthcare.",
    basic_rate: "At the Basic Rate please send us 1 coworker, friend or family member within a month of your appointment. They can schedule a treatment or a free consultation.",
    low_income: "Low Rate is limited based on our availability and your financial need. Pease send us 2 coworkers, friends or family members within a month of your appointment. They can schedule a treatment or a free consultation.",
  },
];

const singleTable = [
  {
    title: "",
    width: 100,
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Supporter Rate",
    width: 150,
    dataIndex: "supporter",
    key: "supporter",
  },
  {
    title: "Basic Rate",
    width: 150,
    dataIndex: "basic_rate",
    key: "basic_rate",
  },
];

const singleData = [
  {
    key: 1,
    title: "Follow up",
    supporter: "$140",
    basic_rate: "$100",
  },
  {
    key: 2,
    title: "Initial",
    supporter: "$170",
    basic_rate: "$130",
  },
  {
    key: 3,
    title: "",
    supporter: "At this rate you are a supporter of affordable holistic healthcare.",
    basic_rate: "",
  },
];
export default function () {
  return (
    <div className="pricing-banner">
      <div className="logo-container">
        <img className="logo" src={logo} alt="Bodypolitic Logo" />
      </div>
      <div className="tables-container section-heading">
        <h2 className="table-header">Treatment Options</h2>
        <p style={{marginBottom: '5px', color: '#141D4C'}}>The rates below are for any modality: Acupuncture, Herbal Consultation, Sliding Cupping, Body Work, Gua Sha, or Magnets. We call these “Acupuncture/Herbal Consultation” on the schedule.</p>

        {/* <h2 className="table-header" style={{marginBottom: '5px'}}>Membership:</h2>
        <p style={{marginBottom: '5px', color: '#141D4C'}}>For discounts and complimentary services</p>
        <strong>$29 per month gives you:</strong>
        <ul style={{marginBottom: '24px'}}>
          <li>20% off of herbs/supplements.</li>
          <li>Complementary stationary cupping or ear seeds with practitioner’s recommendation (Please let practitioner know at beginning of treatment if you would like this).</li>
          <li>$10 off community treatments (Basic and Supporter Rates).</li>
          <li>Add-Ons $10 less than cost of main appointment (Basic and Supporter Rates).</li>
        </ul> */}

        <h2 className="table-subheader">Add-On :</h2><p>Add bodywork, cupping, gua sha or herbal consultation to your single or community room treatment for $40. Just schedule "Add-On" as a second appointment back to back with your original appointment, or about an hour after the start time of your original appointment. Text or call us if you’d like us to schedule for you.</p>

        <a
            href="https://bodypolitic.janeapp.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{marginBottom:'50px', display: 'block'}}
          >
            <Button className="repeating-client">Schedule appointment</Button>
          </a>
        <Row gutter={50}>
          <Col lg={24} xxl={12}>
            <h2 className="table-subheader">Community Room Treatments</h2>
            <Table
            scroll={{ x: 600 }}
              columns={communityColumn}
              dataSource={communityData}
              pagination={false}
              bordered={true}
              size="middle"
              rowClassName="table-row"
              className="table"
              style={{marginBottom:'50px'}}
            />
          </Col>
          <Col lg={24} xxl={12}>
            <h2 className="table-subheader">Single Room Treatments</h2>

            <Table
              columns={singleTable}
              dataSource={singleData}
              pagination={false}
              bordered={true}
              size="middle"
              rowClassName="table-row"
              className="table"
              style={{marginBottom:'50px'}}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
