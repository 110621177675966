import React from "react";
import { Row, Col, Card, Avatar } from "antd";
import Fade from "react-reveal/Fade";

import client1 from "../../static/images/home/clients/client1.png";
import client2 from "../../static/images/home/clients/client2.png";
import client3 from "../../static/images/home/clients/client3.png";

const { Meta } = Card;

const header = (
  <div className="section-heading client-heading">
    <h2 className="section-header">Testimonanials</h2>
    <h3 className="section-subheader client-subheader">
      Check out our reviews{" "}
    </h3>
  </div>
);
const clients = [
  {
    id: 2,
    name: "Bea “Bibi” Myerson",
    hasHeader: true,
    date: "Feb 8, 2023",
    md: 24,
    xl: 8,
    message:
      '"I have been seeing Dr. Mona Eldahry for both arthritis in my ankle & plantar fasciitis. Both conditions have improved tremendously & because of her treatment I do not need surgery, which was my biggest concern. I convinced my Husband to see Dr. Eldahry also. Whatever your ailment is you should go for a consultation. I have never had acupuncture before & this was my last hope (That worked!).',
    img: client1,
    alt: "Bea “Bibi” Myerson",
  },
  {
    id: 6,
    name: "A. Akiyoshi",
    date: "June 1, 2021",
    md: 24,
    xl: 8,
    message:
      '"The first acupuncture session I did with Mona was at the end of my pregnancy. There were 3 days left for me to complete 41 weeks of pregnancy and I had no contractions. I did an acupuncture session with Mona and the next day the contractions started and I went into labor. On our second session I came back because I’ve been having some problems breastfeeding my daughter. I have little milk and a lot of engorgement, I was attended by Mona yesterday and I am already feeling more hopeful. I feel my milk starting to flow between the ducts. Mona\'s work is just wonderful, I highly recommend!"',
    img: client1,
    alt: "A. Akiyoshi",
  },
  {
    id: 5,
    name: "E",
    date: "Jan 22, 2021",
    md: 24,
    xl: 8,
    message:
      '"I\'ve seen a long list of practitioners, neurologists, doctors for years of migraines and you suggested that I eat higher amounts of iron around the times of menstruation and I\'ve really bumped up my intake for the few days before and during and my migraines are gone! It\'s been the longest stretch in many years (going on 4 months now) and my quality of life has massively improved. THANK YOU SO MUCH!!"',
    img: client1,
    alt: "E",
  },
  {
    id: 4,
    name: "Ryme Katkhouda",
    date: "Aug 01, 2020",
    md: 24,
    xl: 12,
    message:
      '"This is a raving review of the expertise, results & work ethics of Mona Eldahry who has been working on me since she started mastering her acupuncture & started doctorate in Chinese medicine. Without it I would still suffer from overwhelming hay fever, allergies & debilitating migraines that started at 17 2-3 times a week! My immune system would still have been a trap for every flu in town. She also fixed my back pain, numbness in my right hand fingers because of a neck disk injury, head colds & sinus, fatigue, depression, lethargy, sleeplessness ... I was a mess after a major fall and 911 exposure in the lower east side of Manhattan & Wall street! She is putting me back together one visit at a time. Her consultations are thorough & more scientifically based than most doctors I used to go see!! Her diagnostics, reparative acupuncture & herbal healings & cures are on the money. She is up to date on the latest solid modalities! And she sure knows how yo run a space, a program, a team!! I can’t wait to checkout her new practice space. I give her thumbs up!!"',
    img: client1,
    alt: "Ryme Katkhouda",
  },
  {
    id: 1,
    name: "Michelle Soriano",
    date: "Feb 27, 2019",
    md: 24,
    xl: 6,
    message: '"Buena experiencia atención con el paciente muy professional"',
    img: client1,
    alt: "Michelle Soriano Client from Yelp",
  },
  // {
  //   id: 2,
  //   name: "Blake S.",
  //   date: "Dec 22, 2015",
  //   md: 12,
  //   message:
  //     '"Very nurturing and caring practitioner who is always at the cutting edge of wellness research. Our experience with Mona has been very positive. We look forward to seeing her for an acupuncture session."',
  //   img: client2,
  //   alt: "Blake S. Client from Yelp",
  // },
  {
    id: 3,
    name: "Ruah B.",
    date: "June 29, 2018",
    md: 24,
    xl: 6,
    message:
      '"I highly recommend Mona Eldahry, she has an incredible healing touch and wonderfully caring presence that is truly impressive.  Thanks to her acupuncture and body work techniques I felt an incredible release physically, mentally and emotionally.  I got so much out of our session - actual healing for my back injury and feeling a release and relaxation in my entire body.  I have already scheduled my next appointment!"',
    img: client3,
    alt: "Ruah B. Client from Yelp",
  },
];
export default function Testimonials() {
  return (
    <div className="testimonials">
      <Row gutter={8} className="client-row">
        {clients.map((client) => (
          <Col key={client.id} xs={24} md={client.md} xl={client.xl}>
            {client.hasHeader && header}
            <Fade>
              <Card className="client-card">
                <Meta
                  avatar={
                    <Avatar
                      size="large"
                      className="client-profile"
                      src={client.img}
                      alt={client.alt}
                    />
                  }
                  title={
                    <div className="card-header">
                      <h4 className="client-name">{client.name}</h4>
                      <p className="client-date">{client.date}</p>
                    </div>
                  }
                />
                <p className="client-message">{client.message}</p>
              </Card>
            </Fade>
          </Col>
        ))}
        {/* <Col xs={24}>
          <Fade>
            <Card className="client-card">
              <Meta
                avatar={
                  <Avatar
                    size="large"
                    className="client-profile"
                    src={client1}
                    alt="Ruah Client Feedback"
                  />
                }
                title={
                  <div className="card-header">
                    <h4 className="client-name">Ryme Katkhouda</h4>
                    <p className="client-date">Aug 01, 2020</p>
                  </div>
                }
              />
              <p className="client-message">
                "This is a raving review of the expertise, results &amp; work
                ethics of Mona Eldahry who has been working on me since she
                started mastering her acupuncture &amp; started doctorate in
                Chinese medicine. Without it I would still suffer from
                overwhelming hay fever, allergies &amp; debilitating migraines
                that started at 17 2-3 times a week! My immune system would
                still have been a trap for every flu in town. She also fixed my
                back pain, numbness in my right hand fingers because of a neck
                disk injury, head colds &amp; sinus, fatigue, depression,
                lethargy, sleeplessness ... I was a mess after a major fall and
                911 exposure in the lower east side of Manhattan &amp; Wall
                street! She is putting me back together one visit at a time. Her
                consultations are thorough &amp; more scientifically based than
                most doctors I used to go see!! Her diagnostics, reparative
                acupuncture &amp; herbal healings &amp; cures are on the money.
                She is up to date on the latest solid modalities! And she sure
                knows how yo run a space, a program, a team!! I can’t wait to
                checkout her new practice space. I give her thumbs up!!"
              </p>
            </Card>
          </Fade>
        </Col> */}

        {/* <Col xs={24}>
          <Fade>
            <Card className="client-card">
              <Meta
                avatar={
                  <Avatar
                    size="large"
                    className="client-profile"
                    src={client1}
                    alt="Ruah Client Feedback"
                  />
                }
                title={
                  <div className="card-header">
                    <h4 className="client-name">E</h4>
                    <p className="client-date">Jan 22, 2021</p>
                  </div>
                }
              />
              <p className="client-message">
                "I've seen a long list of practitioners, neurologists, doctors
                for years of migraines and you suggested that I eat higher
                amounts of iron around the times of menstruation and I've really
                bumped up my intake for the few days before and during and my
                migraines are gone! It's been the longest stretch in many years
                (going on 4 months now) and my quality of life has massively
                improved. THANK YOU SO MUCH!!"
              </p>
            </Card>
          </Fade>
        </Col> */}
        {/* <Col xs={24}>
          <Fade>
            <Card className="client-card">
              <Meta
                avatar={
                  <Avatar
                    size="large"
                    className="client-profile"
                    src={client1}
                    alt="Ruah Client Feedback"
                  />
                }
                title={
                  <div className="card-header">
                    <h4 className="client-name">A. Akiyoshi</h4>
                    <p className="client-date">June 1, 2021</p>
                  </div>
                }
              />
              <p className="client-message">
                "The first acupuncture session I did with Mona was at the end of my pregnancy. There were 3 days left for me to complete 41 weeks of pregnancy and I had no contractions. I did an acupuncture session with Mona and the next day the contractions started and I went into labor.
On our second session I came back because I’ve been having some problems breastfeeding my daughter. I have little milk and a lot of engorgement, I was attended by Mona yesterday and I am already feeling more hopeful. I feel my milk starting to flow between the ducts.
Mona's work is just wonderful, I highly recommend!"
              </p>
            </Card>
          </Fade>
        </Col> */}
      </Row>
    </div>
  );
}
