import React from "react";

export default function CovidNote() {
  return (
    <div className="covid-note">
      <h2 className="heading">A note on pricing during COVID-19:</h2>
      <p className="description">
      Pricing reflects the necessary changes to our protocols: screening, distancing, disinfecting, as well as additional equipment and supplies.
      </p>
    </div>
  );
}
