import React from "react";
import { Row, Col, Button, Tag } from "antd";
import ProgressiveImage from "react-progressive-image";
import Fade from "react-reveal/Fade";

// Images
import mona from "../../static/images/about/mona.png";
import monaPlaceholder from "../../static/images/placeholders/mona-placeholder.png";

import rana from "../../static/images/about/rana.png";
import ranaPlaceholder from "../../static/images/about/rana.png";

import robert from "../../static/images/about/robert.png";
import robertPlaceholder from "../../static/images/placeholders/robert-placeholder.png";

import joann from "../../static/images/about/joann.png";

export default function Personnel() {
  return (
    <div className="personnel">
      <div className="mona">

        <div className="dark-background-right" />

        <Row gutter={16}>
          <Col className="left-col" xs={24} lg={12} xl={10}>
            <div className="section-heading">
              <h2 className="personnel-name section-subheader">Dr. Mona Eldahry, L.Ac., DACM, Dipl. OM</h2>
              <div className="image-container mobile">
                <ProgressiveImage
                  src={mona}
                  placeholder={monaPlaceholder}
                  delay={1500}
                >
                  {(src, loading) => (
                    <img
                      className="mobile"
                      style={{ filter: loading ? "blur(1px)" : "blur(0px)" }}
                      width="100%"
                      src={src}
                      alt="Mona Eldahry"
                    />
                  )}
                </ProgressiveImage>
              </div>
              <Fade>
                <p className="personnel-description section-description">
                Dr. Mona studied acupuncture, herbal medicine and manual techniques at Pacific College of Health and Science, where she completed a Doctorate in Acupuncture and Chinese Medicine (DACM).
                </p>
                <p className="personnel-description section-description">
                  She received training in acupuncture and manual techniques for
                  labor and delivery at her alma mater and at NYU Lutheran
                  Medical Center, where she treated laboring women and
                  individuals recovering from stroke.
                </p>
                <p className="personnel-description section-description">
                  She studies ortho-bionomy, a gentle postural realignment
                  technique, with the Society of Ortho-bionomy.
                </p>
                <p className="personnel-description section-description">
                  In addition to her clinical training in perinatal health, Mona
                  has also received clinical training in pediatric care, weight
                  management and HIV/AIDS.{" "}
                </p>
                <p className="personnel-description section-description">
                  Mona entered healthcare after a long career as a community
                  organizer and popular educator. She believes that quality,
                  affordable healthcare can empower communities.
                </p>
              </Fade>
              <div className="languanges-container">
                  <h3 className="personnel-languages section-subheader">
                  Modalities:
                  </h3>
                  <div className="tags-container">
                    <Tag title="Herbal/Dietary Consultation" color="#DEB722">
                    Herbal/Dietary Consultation
                    </Tag>
                    <Tag title="Cupping" color="#DEB722">
                    Cupping
                    </Tag>

                    <Tag title="Body Work" color="#DEB722">
                    Body Work
                    </Tag>

                    <Tag title="Magnets" color="#DEB722">
                    Magnets
                    </Tag>
                  </div>
                </div>
              <a
                href="https://bodypolitic.janeapp.com/#/staff_member/1"
                rel="noopener noreferrer"
                target="blank"
              >
                <div className="languanges-container">
                  <h3 className="personnel-languages section-subheader">
                    Languages:
                  </h3>
                  <div className="tags-container">
                    <Tag title="Spanish" color="#DEB722">
                      Spanish
                    </Tag>
                    <Tag title="Arabic" color="#DEB722">
                      Arabic
                    </Tag>

                    <Tag title="English" color="#DEB722">
                      English
                    </Tag>
                  </div>
                </div>
                <Button className="personnel-button">Book with Mona</Button>
              </a>
              
            </div>
          </Col>
          <Col className="right-col" xs={24} lg={12} xl={14}>
            <div className="image-container desktop">
              <ProgressiveImage
                src={mona}
                placeholder={monaPlaceholder}
                delay={1500}
              >
                {(src, loading) => (
                  <img
                    className="desktop"
                    style={{ filter: loading ? "blur(1px)" : "blur(0px)" }}
                    width="100%"
                    src={src}
                    alt="Mona Eldahry"
                  />
                )}
              </ProgressiveImage>
            </div>
          </Col>
        </Row>
      </div>
      
      <div className="doctor">
        <div className="dark-background-left" />

        <Row gutter={16}>
          <Col className="left-col" xs={24} lg={12} xl={14}>
            <div className="image-container julian-image desktop">
              <ProgressiveImage
                src={joann}
                placeholder={joann}
                delay={1500}
              >
                {(src, loading) => (
                  <img
                    className="desktop"
                    style={{ filter: loading ? "blur(1px)" : "blur(0px)" }}
                    width="100%"
                    src={src}
                    alt="joann"
                  />
                )}
              </ProgressiveImage>
            </div>
          </Col>
          <Col className="right-col" xs={24} lg={12} xl={10}>
            <div className="section-heading">
              <h2 className="personnel-name section-subheader">Joann Chan, L.Ac., DACM</h2>
              <div className="image-container mobile">
                <ProgressiveImage
                  src={joann}
                  placeholder={joann}
                  delay={1500}
                >
                  {(src, loading) => (
                    <img
                      className="mobile"
                      style={{ filter: loading ? "blur(1px)" : "blur(0px)" }}
                      width="100%"
                      src={src}
                      alt="joann"
                    />
                  )}
                </ProgressiveImage>
              </div>
              <Fade>
                <p className="personnel-description section-description">
                Dr. Chan is a Doctor of Acupuncture and Chinese Medicine (DACM), Licensed Acupuncturist and Herbalist from Pacific College of Health and Science in NYC. She also holds a Bachelor of Science in Accounting from New York University and has maintained a successful career in finance working in credit risk, auditing, credit research, real estate sales and property management.
                </p>
                <p className="personnel-description section-description">
                Joann has been passionate about natural health transformation since her adolescence when her parents’ health became challenged. The search for a more wholistic approach led her into the field of Chinese medicine and herbs. During her training, she completed internships at NYU Langone's initiative for Women with Disabilities working with multi-disciplinary health-related issues.
                </p>
                <p className="personnel-description section-description">
                Joann has worked with patients with history of stroke, auto-immune disorders, gynecological issues, fibromyalgia, digestive issues, anxiety, physical and emotional trauma, cancer care and various pain management complaints. She is passionate about helping patients heal and guiding them in their self-care as a whole mind, body and spirit process with the ancient art of Chinese Medicine.
                </p>
              </Fade>
              <div className="languanges-container">
                  <h3 className="personnel-languages section-subheader">
                  Modalities:
                  </h3>
                  <div className="tags-container">
                    <Tag title="Herbal/Dietary Consultation" color="#DEB722">
                    Herbal/Dietary Consultation
                    </Tag>
                    <Tag title="Cupping" color="#DEB722">
                    Cupping
                    </Tag>

                    <Tag title="Gua Sha" color="#DEB722">
                    Gua Sha
                    </Tag>
                  </div>
                </div>
              <a
                href="https://bodypolitic.janeapp.com/#/staff_member/21"
                rel="noopener noreferrer"
                target="blank"
              >
                <div className="languanges-container">
                  <h3 className="personnel-languages section-subheader">
                    Languages:
                  </h3>
                  <div className="tags-container">
                    <Tag title="English" color="#DEB722">
                    English
                    </Tag>
                    <Tag title="Cantaonese" color="#DEB722">
                    Cantaonese 
                    </Tag>
                    <Tag title="Mandarin Chinese" color="#DEB722">
                    Mandarin Chinese
                    </Tag>
                  </div>
                </div>
                <Button className="personnel-button">Book with Joann</Button>
              </a>
            </div>
          </Col>
        </Row>
      </div>

      <div className="doctor">

        <div className="dark-background-right" />

        <Row gutter={16}>
          <Col className="left-col" xs={24} lg={12} xl={10}>
            <div className="section-heading">
              <h2 className="personnel-name section-subheader">Robert Branch, L.Ac., MSTOM, Dipl. OM</h2>
              <div className="image-container mobile">
                <ProgressiveImage
                  src={robert}
                  placeholder={robertPlaceholder}
                  delay={1500}
                >
                  {(src, loading) => (
                    <img
                      className="mobile"
                      style={{ filter: loading ? "blur(1px)" : "blur(0px)" }}
                      width="100%"
                      src={src}
                      alt="Robert Branch"
                    />
                  )}
                </ProgressiveImage>
              </div>
              <Fade>
                <p className="personnel-description section-description">
                Robert is a New York State Licensed Acupuncturist who has been in practice for over 20 years. He trained at Pacific College of Oriental Medicine where he received a Master of Science in Traditional Oriental Medicine, involving both acupuncture and Chinese herbal medicine. Robert also trained in acupuncture cancer care at the Bendheim Center For Integrative Medicine at Memorial-Sloan Kettering hospital in New York City where he participated in clinical trials and patient care. Robert specializes in pain and stress management, but has treated a variety of health issues over the years. Robert is also a Certified Medical Qi Gong practitioner and enjoys performing daily Qi Gong exercises, Tai Chi and  “Chi Running,” which emphases correct alignment, breathing, a strong core and a relaxed body in order to reduce overuse and impact injuries. Robert’s approach to acupuncture therapy incorporates his belief in the energetic synthesis of mind, body and spirit, always mindful of its effect on the “whole” person.
                </p>
              </Fade>
              <div className="languanges-container">
                  <h3 className="personnel-languages section-subheader">
                  Modalities:
                  </h3>
                  <div className="tags-container">
                    <Tag title="Cupping" color="#DEB722">
                    Cupping
                    </Tag>
                    <Tag title="Gua Sha" color="#DEB722">
                    Gua Sha
                    </Tag>
                  </div>
                </div>
              <a
                href="https://bodypolitic.janeapp.com/#/staff_member/20"
                rel="noopener noreferrer"
                target="blank"
              >
                <div className="languanges-container">
                  <h3 className="personnel-languages section-subheader">
                    Languages:
                  </h3>
                  <div className="tags-container">
                    <Tag title="English" color="#DEB722">
                      English
                    </Tag>
                  </div>
                </div>
                <Button className="personnel-button">Book with Robert</Button>
              </a>
            </div>
          </Col>
          <Col className="right-col" xs={24} lg={12} xl={14}>
            <div className="image-container desktop">
              <ProgressiveImage
                src={robert}
                placeholder={robertPlaceholder}
                delay={1500}
              >
                {(src, loading) => (
                  <img
                    className="desktop"
                    style={{ filter: loading ? "blur(1px)" : "blur(0px)" }}
                    width="100%"
                    src={src}
                    alt="Robert Branch"
                  />
                )}
              </ProgressiveImage>
            </div>
          </Col>
        </Row>
      </div>

      {/* <div className="doctor">
        <div className="dark-background-left" />

        <Row gutter={16}>
                  
        <Col className="left-col" xs={24} lg={12} xl={14}>
            <div className="image-container julian-image desktop">
              <ProgressiveImage
                src={rana}
                placeholder={ranaPlaceholder}
                delay={1500}
              >
                {(src, loading) => (
                  <img
                    className="desktop"
                    style={{ filter: loading ? "blur(1px)" : "blur(0px)" }}
                    width="100%"
                    src={src}
                    alt="Rana Hamdan"
                  />
                )}
              </ProgressiveImage>
            </div>
          </Col>

        <Col className="right-col" xs={24} lg={12} xl={10}>
            <div className="section-heading">
              <h2 className="personnel-name section-subheader">Rana Hamdan, L.Ac. </h2>
              <div className="image-container mobile">
                <ProgressiveImage
                  src={rana}
                  placeholder={ranaPlaceholder}
                  delay={1500}
                >
                  {(src, loading) => (
                    <img
                      className="mobile"
                      style={{ filter: loading ? "blur(1px)" : "blur(0px)" }}
                      width="100%"
                      src={src}
                      alt="Rana Hamdan"
                    />
                  )}
                </ProgressiveImage>
              </div>
              <Fade>
                <p className="personnel-description section-description">
                  In witnessing my own and countless others' healing journeys I
                  have seen human physiological processes be efficient,
                  resourceful and smooth flowing at times, and glitch-y,
                  deficient, and wasteful at times—in both cases, our systems
                  seek harmony. Acupuncture effectively and empirically lends
                  the body a hand in this process of rebalancing itself back
                  into harmony, and I have found it to be a profoundly
                  integrative approach to healing, self-healing, prevention, and
                  wellness.
                </p>
                <p className="personnel-description section-description">
                  I was born and raised in Jerusalem. My upbringing and
                  education at the local Quaker school instilled in me values
                  that I carry with me to this day: God in every living thing,
                  integrity, peace, compassion, simplicity, stewardship,
                  justice, learning and community.
                </p>
                <p className="personnel-description section-description">
                  As an Arab, rooted in community and in transversal indigenous
                  realities, experiences and histories, I see my identity beyond
                  my prescribed gender, national origin, and religion. I've
                  sought a humanitarian skillset that will come to define me,
                  that will allow me to be in service of my chosen global
                  community and fulfill my purpose as a facilitator of harmony
                  in this world.
                </p>
                <p className="personnel-description section-description">
                  I received my Master of Acupuncture from Pacific College for
                  Health and Science (formerly Pacific College of Oriental
                  Medicine). My training included acupuncture, cupping, gua sha,
                  electro-acupuncture, moxibustion and tui Na.
                </p>
                <p className="personnel-description section-description">
                  During my time there I worked with patients at the Pacific
                  College Clinic, Columbia University Student Health Center and
                  NYU Langone Orthopedic Hospital, where I was involved with the
                  Initiative for Women with Disabilities at the Elly and Steve
                  Hammerman Health &amp; Wellness Center. In these settings I was
                  involved in the provision of care for patients with a variety
                  of conditions, including muscle pain, digestive issues, mental
                  health, insomnia, women’s health, headaches and various
                  neuropathies that resulted from multiple sclerosis, stroke and
                  Parkinson’s disease.
                </p>
              </Fade>

              <div className="languanges-container">
                  <h3 className="personnel-languages section-subheader">
                  Modalities:
                  </h3>
                  <div className="tags-container">
                    <Tag title="Cupping" color="#DEB722">
                    Cupping
                    </Tag>
                  </div>
                </div>
              <a
                href="https://bodypolitic.janeapp.com/#/staff_member/18"
                rel="noopener noreferrer"
                target="blank"
              >
                <div className="languanges-container">
                  <h3 className="personnel-languages section-subheader">
                    Languages:
                  </h3>
                  <div className="tags-container">
                    <Tag title="Spanish" color="#DEB722">
                    Spanish
                    </Tag>
                    <Tag title="Arabic" color="#DEB722">
                    Arabic 
                    </Tag>
                    <Tag title="English" color="#DEB722">
                    English
                    </Tag>
                  </div>
                </div>
                <Button className="personnel-button">Book with Rana</Button>
              </a>
            </div>
          </Col>

        </Row>
      </div> */}
    
    </div>
  );
}
